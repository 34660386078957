/*eslint-disable */
let ApiResponse = {
  /**
   * parseServerSideValidation
   */
  async parseServerSideValidation (self, ApiValidationErrResponse) {
    let toastMsg = ''
    if (!(typeof ApiValidationErrResponse === 'object' && !Array.isArray(ApiValidationErrResponse) && ApiValidationErrResponse !== null)) {
      // We should never get string values from api response in this function
      toastMsg = (ApiValidationErrResponse && ApiValidationErrResponse.length > 2) ? ApiValidationErrResponse : "Validation Error"
      return toastMsg
    }

    for( let errMsg in ApiValidationErrResponse) {
      toastMsg += `${ApiValidationErrResponse[errMsg].message} <br />`
    }
    return toastMsg
  },
  /**
   * responseMessageDisplay
   */
  async responseMessageDisplay (self, apiResp, toasTitle="") {
    if (apiResp && apiResp.resp_status) {
      self.toasTitle ? self.toasTitle : ""
      self.toastVariant = 'success'
      self.toastMsg = apiResp.resp_msg  
      self.showToast = true
    }
    else {
      if (apiResp.resp_code === 'ERR_VALIDATION') {
        self.toastMsg = await ApiResponse.parseServerSideValidation(self, apiResp.resp_msg)
      }
    else {
        self.toastMsg = apiResp.resp_msg
      }
      self.toastVariant = 'danger'
      self.showToast = true
    }
    return true
  }
}

export default ApiResponse
