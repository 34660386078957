function userPermission (context, userData, actionName = null) {
  if (userData.user_role == "USERROLE11111") {
    return true
  }

  // Check if User has permission to perform this action
  if (userData.user_permission && JSON.stringify(userData.user_permission).includes(actionName)) {
    return true
  }

  // console.error(`Unauthorized access on ${actionName}`)
  return false
}

export default userPermission
